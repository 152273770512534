import { createPoaHandlerProps, PoaHandler } from '../difinitions';
export const createPoaHandler = function (props?: createPoaHandlerProps): PoaHandler {
	const { poa, poaConfirmChecked = false } = props ?? { poa: null, poaConfirmChecked: false };
	return {
		_selectedPoa: poa,
		get: function () {
			const handler = this;
			return handler._selectedPoa;
		},
		set: function (poa) {
			const handler = this;
			handler._selectedPoa = poa;
		},
		//окно "использовать МЧД?"
		_poaConfirmChecked: poaConfirmChecked,
		setPoaConfirmCheck: function (poaConfirmChecked) {
			const handler = this;
			handler._poaConfirmChecked = poaConfirmChecked;
		},
		getPoaConfirmCheck: function () {
			const handler = this;
			return handler._poaConfirmChecked;
		}
	};
};
