import { CertificateHandler, createCertificateHandlerProps } from '../difinitions';

export const createCertificateHandler = function (props?: createCertificateHandlerProps): CertificateHandler {
	const { certificate, certObj = null, isLocal } = props ?? { certificate: null, certObj: null };
	return {
		_selectedCertificate: certificate,
		_selectedCertObj: certObj,
		_isLocalCert: isLocal,
		get: function () {
			const handler = this;
			return handler._selectedCertificate;
		},
		set: function (cert, certObj, isLocalCert) {
			const handler = this;
			//сохранять будем только если аргумент передавался, иначе мы потеряем данные, которые уже были сохранены,
			//например при вызове set(cert)
			if (arguments.length >= 1) {
				handler._selectedCertificate = cert;
			}
			if (arguments.length >= 2) {
				handler._selectedCertObj = certObj;
			}
			if (arguments.length >= 3) {
				handler._isLocalCert = !!isLocalCert;
			}
		}
	};
};
