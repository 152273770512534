const i18n = (key) => edi.i18n.getMessage(key);

class OrgSelectorTplBuilder {
	constructor(orgSelector) {
		this.orgSelector = orgSelector;
	}

	getTpl() {
		const builder = this;
		const templateItems = builder.getTplRows(builder.orgSelector);
		const templateMethods = builder.getTplMethods(builder.orgSelector);
		const template = new Ext.XTemplate(...templateItems, templateMethods);
		template.selectorInstance = builder.orgSelector;
		return template;
	}

	getTplRows() {
		const builder = this;
		return [
			...builder.getMainBlockTpl(),
			...builder.getInnKppSubdivisionTpl(),
			...builder.getGlnRegNumberTpl(),
			...builder.getOkpoTpl(),
			...builder.getSnilsTpl(),
			...builder.getAddressTpl(),
			...builder.getBankTpl(),
			...builder.getOperatorTpl(),
			...builder.getRepresentativeTpl(),
			...builder.getContactTpl(),
			...builder.getLicenseTpl()
		];
	}

	createSingleValueRow({
		title,
		name,
		customCondition,
		codeCls,
		customCodeValue,
		customCodeTag,
		valueCls,
		customValue,
		customValueTag
	}) {
		const condition = customCondition ?? `!Ext.isEmpty($v.UI Platform Maven Webapp)`;
		const codeTag =
			customCodeTag ?? `<span class="code ${codeCls || ''}">${customCodeValue ?? i18n(title)}:</span>`;
		const valueTag =
			customValueTag ?? `<span class="row-data ${valueCls}">${customValue ?? '{' + name + '}'}</span>`;
		return [`<tpl if="${condition}">`, '<div class="edi-company-row">', codeTag, valueTag, '</div>', '</tpl>'];
		//если передадим конфиг
		//{title: 'company.gln.short', name: 'company_iln', valueCls: 'edi-company-gln'}
		//то получим такой вот шаблон
		//<tpl if="!Ext.isEmpty($v.company_iln)">
		//	<div class="edi-company-row">
		//		<span class="code">${i18n('company.gln.short')}:</span>
		//		<span class="row-data edi-company-gln"> {company_iln} </span>
		//	</div>
		//</tpl>
	}

	createCodeValue({
		title,
		name,
		customCondition,
		codeCls,
		customCodeValue,
		customCodeTag,
		valueCls,
		customValue,
		customValueTag
	}) {
		const condition = customCondition ?? `!Ext.isEmpty($v.UI Platform Maven Webapp)`;
		const codeTag =
			customCodeTag ?? `<span class="code ${codeCls || ''}">${customCodeValue ?? i18n(title)}:</span>`;
		const valueTag =
			customValueTag ?? `<span class="row-data ${valueCls}">${customValue ?? '{' + name + '}'}</span>`;
		return [`<tpl if="${condition}">`, codeTag, valueTag, '</tpl>'];
	}

	createCodeValuesRow(configs) {
		const builder = this;
		const condition = configs
			.map(({ name, customCondition }) => customCondition ?? `!Ext.isEmpty($v.UI Platform Maven Webapp)`)
			.join(' || ');
		const items = configs.map((cfg) => builder.createCodeValue(cfg)).flat();
		return [`<tpl if="${condition}">`, '<div class="edi-company-row">', ...items, '</div>', '</tpl>'];
		//если передадим массив из 2 конфигов
		//[
		// {title: 'company.gln.short', name: 'company_iln', valueCls: 'edi-company-gln'},
		// {title: 'company.util.reg.nr', name: 'company_util_reg_nr', valueCls: 'edi-company-util-reg-nr'}
		//]
		//то получим такой вот шаблон
		//<tpl if="!Ext.isEmpty($v.company_iln || $v.company_util_reg_nr)">
		//	<div class="edi-company-row">
		//		<tpl if="!Ext.isEmpty($v.company_iln)">
		//			<span class="code">${i18n('company.gln.short')}:</span>
		//			<span class="row-data edi-company-gln"> {company_iln} </span>
		//		</tpl>
		//		<tpl if="!Ext.isEmpty($v.company_util_reg_nr)">
		//			<span class="code">${i18n('company.util.reg.nr')}:</span>
		//			<span class="row-data edi-company-util-reg-nr"> {company_util_reg_nr} </span>
		//		</tpl>
		//	</div>
		//</tpl>
	}

	getMainBlockTpl() {
		const builder = this;
		return [
			`<tpl if="$v.type === 'company'">`,
			...builder.getMainBlockLegalEntityTpl(),
			`<tpl elseif="$v.type === 'individual'">`,
			...builder.getMainBlockIndividualTpl(),
			`<tpl elseif="$v.type === 'individualParticipant'">`,
			...builder.getMainBlockPhysicalTpl(),
			`<tpl elseif="$v.type === 'legalForeigner'">`,
			...builder.getMainBlockLegalForeignerTpl(),
			`<tpl elseif="$v.type === 'companyNotRegistered'">`,
			...builder.getMainBlockNotRegisteredTpl(),
			'</tpl>'
		];
	}

	getMainBlockLegalEntityTpl() {
		return [
			'<tpl if="!Ext.isEmpty($v.company_name)">',
			'	<div class="edi-company-name">{company_name}</div>',
			'</tpl>'
		];
	}

	getMainBlockIndividualTpl() {
		const builder = this;
		return [
			'<tpl if="!Ext.isEmpty($v.individual_company_name)">',
			...builder.getMainBlockIndividualCompanyName(),
			'<tpl elseif="!Ext.isEmpty($v.individual_lastname || $v.individual_firstname || $v.individual_patronymicname)">',
			...builder.getMainBlockIndividualFirstLastPatronymicName(),
			'</tpl>',
			...builder.getMainBlockIndividualInn(),
			...builder.getMainBlockIndividualRegistration()
		];
	}

	getMainBlockIndividualCompanyName() {
		return [
			'<div class="edi-company-name">',
			'	<span class="row-data edi-company-individual-company-name">{individual_company_name}</span>&nbsp;',
			'</div>'
		];
	}

	getMainBlockIndividualFirstLastPatronymicName() {
		return [
			'<div class="edi-company-name">',
			'	<span class="row-data edi-company-individual-lastname">{individual_lastname}</span>',
			'	<span class="row-data edi-company-individual-firstname"> {individual_firstname}</span>',
			'	<span class="row-data edi-company-individual-patronymicname"> {individual_patronymicname}</span>&nbsp;',
			'</div>'
		];
	}

	getMainBlockIndividualInn() {
		const builder = this;
		return builder.createCodeValuesRow([
			{
				title: 'company.inn.short',
				name: 'individual_inn',
				valueCls: 'edi-company-individual-inn'
			}
		]);
	}

	getMainBlockIndividualRegistration() {
		const builder = this;
		return builder.createCodeValuesRow([
			{
				title: 'individual.certificateRegistration',
				name: 'individual_certificateRegistrationIP',
				valueCls: 'edi-company-individual-registration'
			}
		]);
	}

	getMainBlockPhysicalTpl() {
		return [
			'<tpl if="!Ext.isEmpty($v.physical_lastname || $v.physical_firstname || $v.physical_patronymicname)">',
			'<div class="edi-company-name">',
			'	<span class="row-data edi-company-physical-lastname">{physical_lastname}</span>',
			'	<span class="row-data edi-company-physical-firstname"> {physical_firstname}</span>',
			'	<span class="row-data edi-company-physical-patronymicname"> {physical_patronymicname}</span>',
			'</div>',
			'</tpl>'
		];
	}

	getMainBlockLegalForeignerTpl() {
		const builder = this;
		return [
			...builder.getMainBlockLegalForeignerCompanyName(),
			...builder.getMainBlockLegalForeignerInfo(),
			...builder.getMainBlockLegalForeignerIdentifier()
		];
	}

	getMainBlockLegalForeignerCompanyName() {
		return [
			'<tpl if="!Ext.isEmpty($v.foreign_name)">',
			'<div class="edi-company-name">{foreign_name}</div>',
			'</tpl>'
		];
	}

	getMainBlockLegalForeignerInfo() {
		const builder = this;
		return builder.createCodeValuesRow([
			{
				title: 'company.type.legalForeigner.header.info',
				name: 'foreign_info',
				valueCls: 'edi-company-address'
			}
		]);
	}

	getMainBlockLegalForeignerIdentifier() {
		const builder = this;
		return builder.createCodeValuesRow([
			{
				title: 'column.indentifier',
				name: 'foreign_identifier',
				valueCls: 'edi-foreign-identifier'
			}
		]);
	}

	getMainBlockNotRegisteredTpl() {
		const builder = this;
		return [...builder.getMainBlockNotRegisteredName(), ...builder.getMainBlockNotRegisteredInfo()];
	}

	getMainBlockNotRegisteredName() {
		return [
			'<tpl if="!Ext.isEmpty($v.notregistered_name)">',
			'<div class="edi-company-name">{notregistered_name}</div>',
			'</tpl>'
		];
	}

	getMainBlockNotRegisteredInfo() {
		const builder = this;
		return builder.createCodeValuesRow([
			{
				title: 'company.type.legalForeigner.header.info',
				name: 'notregistered_info',
				valueCls: 'edi-company-address'
			}
		]);
	}

	getInnKppSubdivisionTpl() {
		const builder = this;
		return builder.createCodeValuesRow([
			{ title: 'company.inn.short', name: 'company_inn', valueCls: 'edi-company-inn' },
			{ title: 'company.kpp.short', name: 'company_kpp', valueCls: 'edi-company-kpp' },
			{
				title: 'company.structuralSubdivision',
				name: 'structural_subdivision',
				valueCls: 'edi-company-structural-subdivision'
			}
		]);
	}

	getGlnRegNumberTpl() {
		const builder = this;
		return builder.createCodeValuesRow([
			{ title: 'company.gln.short', name: 'company_iln', valueCls: 'edi-company-gln' },
			{ title: 'company.util.reg.nr', name: 'company_util_reg_nr', valueCls: 'edi-company-util-reg-nr' }
		]);
	}

	getOkpoTpl() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'company.okpo.short',
			name: 'company_okpo',
			valueCls: 'edi-company-okpo'
		});
	}

	getSnilsTpl() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'snils.code',
			name: 'person_snils',
			valueCls: 'edi-company-slils',
			customValue: '{[this.convertSnils(values.person_snils)]}'
		});
	}

	getAddressTpl() {
		const builder = this;
		return [
			'<tpl if="!Ext.isEmpty($v.address) && this.isAddressNotGar($v)">',
			`	${builder.getAddressString().join('\n')}`,
			'<tpl elseif="this.isAddressNotGar($v)">',
			'	<tpl if="!Ext.isEmpty($v.addr_for_text)">',
			`		${builder.getForeignAddress().join('\n')}`,
			'	<tpl else>',
			'		<tpl if="this.allowCommonAddressRenderer($v)">',
			`			${builder.getCommonAddress().join('\n')}`,
			'		<tpl else>',
			`			${builder.getLegacyAddress().join('\n')}`,
			`			${builder.getFnsAddress().join('\n')}`,
			'		</tpl>',
			'	</tpl>',
			'<tpl elseif="!Ext.isEmpty($v.addr_code_gar) && !this.isAddressNotGar($v)">',
			`	${builder.getGarAddress().join('\n')}`,
			'</tpl>'
		];
	}

	getAddressString() {
		const builder = this;
		return builder.createSingleValueRow({
			customCodeValue: '{[this.renderAddressCategory(values)]}',
			name: 'address',
			valueCls: 'edi-company-address'
		});
	}

	getForeignAddress() {
		return [
			'<div class="edi-company-row">',
			'	<tpl if="!Ext.isEmpty($v.addr_for_country_name)">',
			'		<span class="code">' + i18n('company.address') + ':</span>',
			'		<tpl if="!Ext.isEmpty($v.addr_for_country)">',
			'			{[this.convertData(["addr_for_country", "addr_for_country_name"], values)]}',
			'		</tpl>',
			'	</tpl>',
			'	<span class="row-data edi-company-addr-text"> {addr_for_text}</span>',
			'</div>'
		];
	}

	getCommonAddress() {
		const builder = this;
		return builder.createSingleValueRow({
			customCondition: 'this.allowCommonAddressRenderer($v)',
			codeCls: 'edi-company-addr-header',
			customCodeValue: '{[this.renderAddressCategory(values)]}',
			customValueTag: '{[this.convertCommonAddress(values)]}'
		});
	}

	getLegacyAddress() {
		const builder = this;
		return builder.createSingleValueRow({
			customCondition:
				'!Ext.isEmpty($v.addr_country || $v.addr_zip || $v.addr_area || $v.addr_block || $v.addr_city || $v.addr_street || $v.addr_home || $v.addr_flat)',
			codeCls: 'edi-company-addr-header',
			customCodeValue: '{[this.renderAddressCategory(values)]}',
			customValueTag: '{[this.convertLegacyAddress(values)]}'
		});
	}

	getFnsAddress() {
		const builder = this;
		return builder.createSingleValueRow({
			customCondition:
				'!Ext.isEmpty($v.addr_rus_zip || $v.addr_rus_region || $v.addr_rus_county || $v.addr_rus_block || $v.addr_rus_city || $v.addr_rus_street || $v.addr_rus_home || $v.addr_rus_flat)',
			codeCls: 'edi-company-addr-header',
			customCodeValue: '{[this.renderAddressCategory(values)]}',
			customValueTag: '{[this.convertRusAddress(values)]}'
		});
	}

	getGarAddress() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'company.addr.code.gar',
			name: 'addr_code_gar',
			valueCls: 'edi-company-gar'
		});
	}

	getBankTpl() {
		const builder = this;
		return builder.createCodeValuesRow([
			{ title: 'company.bank.id', name: 'bank_id', valueCls: 'edi-company-bank-id' },
			{ title: 'company.bank.name', name: 'bank_name', valueCls: 'edi-company-bank-name' },
			{ title: 'company.bank.account', name: 'bank_acc', valueCls: 'edi-company-bank-acc' },
			{ title: 'company.bank.account.corr', name: 'bank_corr_acc', valueCls: 'edi-company-bank-cor-acc' }
		]);
	}

	getOperatorTpl() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'operator.name',
			customCondition: '!Ext.isEmpty($v.operator_name || $v.operator_phone || $v.operator_email)',
			customValueTag: '{[this.convertData(["operator_name", "operator_phone", "operator_email"], values)]}'
		});
	}

	getRepresentativeTpl() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'representative.name',
			customCondition:
				'!Ext.isEmpty($v.representative_name || $v.representative_phone || $v.representative_email)',
			customValueTag:
				'{[this.convertData(["representative_name", "representative_phone", "representative_email"], values)]}'
		});
	}

	getContactTpl() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'contact.name',
			customCondition:
				'!Ext.isEmpty($v.contact_name || $v.contact_phone || $v.contact_email || $v.contact_web || $v.contact_fax || $v.contact_person)',
			customValueTag:
				'{[this.convertData(["contact_name", "contact_phone", "contact_fax", "contact_person", "contact_email", "contact_web"], values)]}'
		});
	}

	getLicenseTpl() {
		const builder = this;
		return [
			'<tpl if="!Ext.isEmpty($v.license_type || $v.license_name || $v.license_series || $v.license_number)">',
			...builder.getLicenseTypeName(),
			...builder.getLicenseSeriesNumber(),
			...builder.getLicenseIssuingAuthority(),
			...builder.getLicenseDates(),
			'</tpl>'
		];
	}

	getLicenseTypeName() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'company.license',
			customCondition: '!Ext.isEmpty($v.license_type || $v.license_name)',
			customValueTag: '{[this.convertData(["license_type", "license_name", "representative_email"], values)]}'
		});
	}

	getLicenseSeriesNumber() {
		const builder = this;
		return builder.createCodeValuesRow([
			{ title: 'company.license.series', name: 'license_series', valueCls: 'edi-company-licence-series' },
			{ title: 'company.license.number', name: 'license_number', valueCls: 'edi-company-licence-number' }
		]);
	}
	getLicenseIssuingAuthority() {
		const builder = this;
		return builder.createSingleValueRow({
			title: 'company.license.issuing.authority',
			name: 'license_issuing_authority',
			valueCls: 'edi-company-licence-authority'
		});
	}

	getLicenseDates() {
		const builder = this;
		return builder.createCodeValuesRow([
			{
				title: 'company.license.date.of.issue',
				name: 'license_date_of_issue',
				valueCls: 'edi-company-licence-date-of-issue'
			},
			{
				title: 'company.license.expiration.date',
				name: 'license_expiration_date',
				valueCls: 'edi-company-expiration-date'
			}
		]);
	}

	getTplMethods() {
		const builder = this;
		return {
			isAddressNotGar: builder.isAddressNotGar,
			allowCommonAddressRenderer: builder.allowCommonAddressRenderer,
			renderAddressCategory: builder.renderAddressCategory,
			convertCommonAddress: builder.convertCommonAddress,
			convertLegacyAddress: builder.convertLegacyAddress,
			convertRusAddress: builder.convertRusAddress,
			convertData: builder.convertData,
			convertSnils: builder.convertSnils
		};
	}

	/**
	 * This is template method this == tpl
	 */
	isAddressNotGar(values) {
		return values.address_type !== '2';
	}

	/**
	 * This is template method this == tpl
	 */
	allowCommonAddressRenderer(values) {
		return this.selectorInstance.disableCommonAddressRenderer !== true && !!values?.addr_category;
	}

	/**
	 * This is template method this == tpl
	 */
	renderAddressCategory(values) {
		return this.selectorInstance.disableCommonAddressTitleRenderer === true || !values?.addr_category
			? i18n('company.address.header')
			: i18n('address.type.' + values.addr_category);
	}

	/**
	 * This is template method this == tpl
	 */
	convertCommonAddress(values) {
		const addrProperties = [
			'addr_country_name',
			'addr_zip',
			'addr_region',
			'addr_area',
			'addr_city',
			'addr_locality',
			'addr_street',
			'addr_home',
			'addr_block',
			'addr_flat'
		];
		values['addr_region'] = values['addr_region'] ? `(${values['addr_region']})` : '';
		return this.convertData(addrProperties, values);
	}

	/**
	 * This is template method this == tpl
	 */
	convertLegacyAddress(values) {
		const addrProperties = [
			'addr_country_name',
			'addr_zip',
			'addr_region',
			'addr_area',
			'addr_city',
			'addr_locality',
			'addr_street',
			'addr_home',
			'addr_block',
			'addr_flat'
		];
		values['addr_region'] = values['addr_region'] ? `(${values['addr_region']})` : '';
		return this.convertData(addrProperties, values);
	}

	/**
	 * This is template method this == tpl
	 */
	convertRusAddress(values) {
		const addrProperties = [
			'addr_rus_zip',
			'addr_rus_region',
			'addr_region_name',
			'addr_rus_county',
			'addr_rus_city',
			'addr_rus_community',
			'addr_rus_street',
			'addr_rus_home',
			'addr_rus_block',
			'addr_rus_flat'
		];
		values['addr_rus_region'] = values['addr_rus_region'] ? `(${values['addr_rus_region']})` : '';
		const valuesWithCustomSeparator = [
			{
				siblingValues: ['addr_rus_region', 'addr_region_name'],
				separator: '<span class="row-data row-data-separator">&nbsp;</span>'
			}
		];
		return this.convertData(addrProperties, values, valuesWithCustomSeparator);
	}

	/**
	 * This is template method this == tpl
	 */
	convertData(addrProperties, values, valuesWithCustomSeparator) {
		return addrProperties
			.filter(function (prop) {
				return values.hasOwnProperty(prop) && values[prop].length;
			})
			.reduce(function (acc, prop, i, arr) {
				const getSeparator = () => {
					const isLastValue = i === arr.length - 1;
					if (isLastValue) {
						return '';
					}
					//Значения между которыми используется свой собственный разделитель
					if (Array.isArray(valuesWithCustomSeparator) && valuesWithCustomSeparator.length) {
						const valueWithCustomSeparator = valuesWithCustomSeparator.find(({ siblingValues }) => {
							const [firstValue, secondValue] = siblingValues || [];
							return prop === firstValue && arr[i + 1] === secondValue && values[secondValue];
						});

						if (valueWithCustomSeparator && valueWithCustomSeparator.separator) {
							return valueWithCustomSeparator.separator;
						}
					}

					return '<span class="row-data row-data-separator">,&nbsp;</span>';
				};
				acc +=
					`<span class='row-data inline-row-data edi-${prop.replace(`_', '-`)}'>` +
					values[prop] +
					'</span>' +
					getSeparator();
				return acc;
			}, '');
	}

	/**
	 * This is template method this == tpl
	 */
	convertSnils(snilsData) {
		return edi.converters.SNILS.convertForShow(snilsData);
	}
}

export { OrgSelectorTplBuilder };
